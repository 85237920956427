<template>
  <v-card-actions class="justify-center">
    <v-btn v-if="canPrev" class="me-3 mt-3" @click.native="$emit('prevStep')">
      Revenir</v-btn
    >
    <v-btn
      v-if="canNext"
      color="primary"
      class="me-3 mt-3"
      @click.native="$emit('nextStep')"
    >
      Continuer</v-btn
    >
    <v-btn
      v-if="canReset"
      color="secondary"
      outlined
      class="mt-3"
      @click.native="$emit('resetStep')"
    >
      Annuler
    </v-btn>
  </v-card-actions>
</template>

<script>
export default {
  name: "PrevNext",
  props: {
    canPrev: {
      type: Boolean,
      required: true,
    },
    canNext: {
      type: Boolean,
      required: true,
    },
    canReset: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
